<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-01 11:35:53
 * @ Description: 用户中心>客户车辆>客户车辆列表>车辆编辑
 -->

<template>
  <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="车牌号码" prop="plateNumber">
      <ykc-input
        v-model="ruleForm.plateNumber"
        :placeholder="'请输入车牌号码'"
        :disabled="editType == 'bind'"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="车辆VIN（车架号）" prop="vinCode">
      <ykc-input
        v-model="ruleForm.vinCode"
        :placeholder="'请输入车辆VIN'"
        :disabled="editType == 'bind'"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="车辆用途" prop="purpose">
      <ykc-dropdown
        placeholder="请选择车辆用途"
        :clearable="false"
        :data="dictionary.vehiclePurpose"
        v-model="ruleForm.purpose"
        :disabled="editType == 'bind'"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item :label="'归属子公司'" prop="belongToId" v-if="editType !== 'bind'">
      <ykc-dropdown
        :remote="true"
        v-model="ruleForm.belongToId"
        placeholder="请输入客户关键字"
        :isObject="true"
        :configSet="configSet"
        :searchMaxLength="1000"
        @change="selectOrg"
        :data="orgList || []"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="车辆产权" prop="propertyRight" v-if="editType !== 'bind'">
      <ykc-dropdown
        placeholder="请选择车辆产权"
        :clearable="false"
        :data="dictionary.vehiclePropertyRight"
        v-model="ruleForm.propertyRight"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="绑定账号" prop="userAccountId" v-if="editType === 'bind'">
      <ykc-dropdown
        v-model="ruleForm.userAccountId"
        placeholder="绑定账号"
        :clearable="true"
        :data="allUsers"
        :multiple="true"></ykc-dropdown>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { loadDicItem } from '@/utils/dictionary';
  import { customerVehicle, customerOrg } from '@/service/apis';

  export default {
    props: {
      id: {
        type: String,
      },
      editType: {
        type: String,
        default: 'add',
      },
    },
    components: {},
    data() {
      return {
        // 机构下拉框数据显示配置
        configSet: {
          label: 'name',
          value: 'id',
        },
        allUsers: [],
        orgList: [],
        plateNumber: '',
        dictionary: {
          vehiclePurpose: [],
          vehiclePropertyRight: [
            { id: '1', name: '个人' },
            { id: '2', name: '机构' },
          ],
        },
        ruleForm: {
          id: '',
          plateNumber: '',
          vinCode: '',
          purpose: '',
          belongToId: '',
          propertyRight: '',
          userAccount: '',
          userAccountId: '',
        },
        rules: {
          plateNumber: [
            { required: true, message: '请输入车牌号码', trigger: 'blur' },
            // { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          vinCode: [{ required: false, message: '请输入车辆VIN', trigger: 'blur' }],
          purpose: [{ required: false, message: '请选择车辆用途', trigger: 'blur' }],
          belongToId: [{ required: true, message: '请输入联想下拉', trigger: 'blur' }],
          propertyRight: [{ required: true, message: '请选择产权机构', trigger: 'blur' }],
        },
      };
    },
    computed: {
      formData() {
        const formData = {
          id: this.ruleForm.id,
          plateNumber: this.ruleForm.plateNumber,
          vinCode: this.ruleForm.vinCode,
          purpose: this.ruleForm.purpose,
          belongToId: this.ruleForm.belongToId,
          propertyRight: this.ruleForm.propertyRight,
          userAccount: this.ruleForm.userAccountId,
          userType: '3', // 1 C端司机 2客户司机 3子公司司机
          orgMold: '2', // 1 客户机构 2 内部公司
          carType: '3', // 1 c 2 客户车辆 3 子公司车辆
        };
        return formData;
      },
    },
    created() {
      this.dictionary.vehiclePurpose = loadDicItem('car_purpose');
      // 获取车队、运营商、电站列表数据
      if (this.editType !== 'add') {
        customerVehicle
          .detail({
            id: this.id,
          })
          .then(res => {
            this.ruleForm.id = res.id;
            this.ruleForm.plateNumber = res.plateNumber;
            this.ruleForm.vinCode = res.vinCode;
            this.ruleForm.purpose = res.purpose;
            this.ruleForm.belongToId = res.belongToId;
            this.ruleForm.propertyRight = res.propertyRight;
            this.ruleForm.userAccount = (res.userAccount || '').split(',');
            this.queryAllUserByOrgId(res.belongToId);
          });
      }
      this.queryMainOrgList();
    },
    methods: {
      queryAllUserByOrgId(id) {
        customerVehicle.queryAllUserByOrgId({ belongToId: id }).then(res => {
          this.ruleForm.userAccountId = this.ruleForm.userAccount
            ? res
                .filter(item => this.ruleForm.userAccount.indexOf(item.userAccount) !== -1)
                .map(item => item.id)
            : [];
          this.allUsers = res.map(item => ({
            id: item.id,
            name: item.userAccount,
          }));
        });
      },
      // 获取机构下拉数据
      queryMainOrgList() {
        customerOrg.queryCompanyAllOrgList({}).then(res => {
          this.orgList = res;
        });
      },
      selectOrg(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.belongToId = e.id;
      },
      /**
       * 车牌唯一性校验
       */
      onlyCheckName(rule, value, callback) {
        if (value && !this.ruleForm.id) {
          customerVehicle
            .checkPlateNumberByMainOrgId({
              plateNumber: this.ruleForm.plateNumber,
            })
            .then(res => {
              console.log(JSON.stringify(res));
              if (res && this.ruleForm.plateNumber !== this.plateNumber) {
                if (res === '2') {
                  callback(new Error('内部车辆车牌重复'));
                } else if (res === '1') {
                  callback(new Error('内部车辆车牌重复'));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            })
            .catch(e => {
              console.log(JSON.stringify(e));
            });
        } else {
          callback();
        }
      },
      submitForm() {
        this.$set(this.rules, 'plateNumber', [
          { required: true, message: '请输入车牌号码', trigger: 'blur' },
          { validator: this.onlyCheckName, trigger: ['blur', 'change'] },
        ]);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.editType === 'add') {
              customerVehicle.add(this.formData).then(() => {
                this.$message({
                  message: '新增成功',
                  type: 'success',
                });
                resolve();
              });
            } else if (this.editType === 'bind') {
              customerVehicle
                .bindUser({
                  carId: this.formData.id,
                  userIds: this.formData.userAccount,
                })
                .then(() => {
                  this.$message({
                    message: '绑定成功',
                    type: 'success',
                  });
                  resolve();
                });
            } else {
              customerVehicle.edit(this.formData).then(() => {
                this.$message({
                  message: '编辑成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
